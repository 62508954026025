import React from "react"
import Slider from "react-animated-slider"

import slide1 from "@assets/images/1compresse.jpg"
import slide2 from "@assets/images/2compresse.jpg"
import slide3 from "@assets/images/compresse3.jpg"
import slide4 from "@assets/images/compresse4.png"

import "./slider-animations.css"
import "./styles.css"
const AnimatedSlider = () => {
  const content = [
    {
      image: slide1,
    },
    {
      image: slide2,
    },
    {
      image: slide3,
    },
    {
      image: slide4,
    },
  ]
  return (
    <Slider autoplay={500} className="slider-wrapper">
      {content.map((item, index) => (
        <div
          key={index}
          className="slider-content home-slider"
          style={{
            background: `url('${item.image}') no-repeat center center`,height:"71vh"
          }}
        >
          <div className="inner">
            <h1>{item.title}</h1>
            <p>{item.description}</p>
          </div>
        </div>
      ))}
    </Slider>
  )
}

export default AnimatedSlider
